import type { EndorsementProblem } from '@orus.eu/backend/src/routers/endorsement'
import type { SubscriptionNonDimensionalData } from '@orus.eu/backend/src/services/subscription/subscription-service'
import { formatTimestampDdMmYyyyAHhMm } from '@orus.eu/calendar-date'
import {
  hasOnlyNonEndorsementChanges,
  type AbstractDimension,
  type PartialDimensionnedState,
} from '@orus.eu/dimensions'
import { Button, colorTokens, Text, typedMemo, useAsyncCallback, useDialogVisibility } from '@orus.eu/pharaoh'
import { ButtonGroup } from '@orus.eu/pharaoh/src/components/button/button-group'
import { EndorsementConfirmDialog } from '@orus.eu/pharaoh/src/components/features/endorsement'
import { Row, RowContainer } from '@orus.eu/pharaoh/src/components/rows'
import { Dropdown } from '@orus.eu/pharaoh/src/patterns/dropdown'
import { useNavigate, useParams } from '@tanstack/react-router'
import { useCallback, useEffect, useState } from 'react'
import { trpc, trpcReact } from '../../../../../client'
import { getHubspotContactLink } from '../../../../../lib/hubspot-util'
import { usePermissions } from '../../../../../lib/use-permissions'
import { openLinkInNewWindow } from '../../../../../lib/window-util'
import { useBuildUrl } from '../../../../../use-build-url'
import { GlobalLoadingState } from '../../../../molecules/global-loading-state'

export type QuoteEditorV2ButtonBarProps<Dimensions extends readonly AbstractDimension[]> = {
  subscriptionId: string
  changes: PartialDimensionnedState<Dimensions>
  nonDimensionalDataAfter: SubscriptionNonDimensionalData
  onReset: () => void
  onValidate: () => void
  currentEmail: string | undefined
  nextEnabledByAllContributions: boolean
  bypassBrowserConfirmationDialog: () => void
}

export const QuoteEditorV2ButtonBar = typedMemo(function QuoteEditorV2ButtonBar<
  Dimensions extends readonly AbstractDimension[],
>(props: QuoteEditorV2ButtonBarProps<Dimensions>) {
  const userType = usePermissions().type
  const { buildUrl } = useBuildUrl()
  const isPlatform = userType === 'platform'
  const { organization } = useParams({ strict: false })
  const { hide, show, visible } = useDialogVisibility('validate-endorsement')
  const hasChanges = Object.keys(props.changes).length > 0
  const hasOnlyUserInfosChanges = hasOnlyNonEndorsementChanges(props.changes)
  const navigate = useNavigate()

  const { currentEmail, bypassBrowserConfirmationDialog, nonDimensionalDataAfter, subscriptionId } = props

  const { data: pendingEndorsements } = trpcReact.endorsement.getPendingEndorsements.useQuery(
    { subscriptionId },
    { enabled: !!props.nonDimensionalDataAfter.contract },
  )

  const {
    isPending: isEndorsementCreationPending,
    mutate: mutateCreateEndorsement,
    data: endorsementCreationData,
    isSuccess: mutateEndorsementCreationSuccess,
  } = trpcReact.endorsement.createEndorsement.useMutation()

  const [shouldReload, setShouldReload] = useState(false)

  const openHubspotContact = useCallback(() => {
    if (!currentEmail) {
      alert(
        "Il n'y a pas d'email associé à ce devis. Cette fonctionnalité n'est " +
          "disponible que lorsqu'un email a été saisi",
      )
      return
    }
    const url = getHubspotContactLink(currentEmail)
    openLinkInNewWindow(url)
  }, [currentEmail])

  const validateChanges = useAsyncCallback(async () => {
    const result = await trpc.endorsement.validateChanges.mutate({
      subscriptionId: props.nonDimensionalDataAfter.subscriptionId,
      changes: props.changes,
    })

    if (result.type === 'failure') {
      alert(getProblemMessage(result.problem))
      return
    }

    hide()
    bypassBrowserConfirmationDialog()
    // to ensure the reload happens only after the bypassBrowserConfirmationDialog has been successfully set
    setShouldReload(true)
  }, [bypassBrowserConfirmationDialog, hide, props.changes, props.nonDimensionalDataAfter.subscriptionId])

  useEffect(() => {
    if (shouldReload) {
      document.location.reload()
    }
  }, [shouldReload])

  if (isEndorsementCreationPending) {
    return <GlobalLoadingState />
  }

  if (mutateEndorsementCreationSuccess) {
    void navigate({ to: `/bak/contracts/${subscriptionId}/endorsement/${endorsementCreationData.endorsementId}` })
  }

  return (
    <>
      <Text variant="body2" color={colorTokens['color-text-base-basic']}>
        Créé le {formatTimestampDdMmYyyyAHhMm(nonDimensionalDataAfter.creationTimestamp)}
      </Text>
      <ButtonGroup>
        {isPlatform ? (
          <Button title="Hubspot" variant="secondary" size="small" onClick={openHubspotContact} icon="hubspot-brands" />
        ) : (
          <></>
        )}
        <Dropdown
          trigger={
            <Button
              variant="secondary"
              size="small"
              icon="ellipsis-regular"
              ariaLabel={'Menu de la page de souscription'}
            />
          }
        >
          <RowContainer variant="border" size="small">
            {props.nonDimensionalDataAfter.contract ? (
              <Row
                subtitle="Voir le contrat"
                leftIcon="file-lines-light"
                key="1"
                href={
                  isPlatform
                    ? buildUrl({
                        to: '/bak/contracts/$subscriptionId',
                        params: { subscriptionId: props.subscriptionId },
                      })
                    : buildUrl({
                        to: '/partner/$organization/contracts/$subscriptionId',
                        params: { organization: organization!, subscriptionId: props.subscriptionId },
                      })
                }
              />
            ) : (
              <></>
            )}
            {props.nonDimensionalDataAfter.contract && isPlatform ? (
              pendingEndorsements && pendingEndorsements.length > 0 ? (
                <Row
                  subtitle="Voir l'avenant"
                  leftIcon="file-light"
                  key="2"
                  href={buildUrl({
                    to: '/bak/contracts/$subscriptionId/endorsement/$endorsementId',
                    params: { subscriptionId, endorsementId: pendingEndorsements[0].id },
                  })}
                />
              ) : (
                <Row
                  subtitle="Nouvel avenant"
                  leftIcon="file-light"
                  key="2"
                  onClick={() => {
                    bypassBrowserConfirmationDialog()
                    mutateCreateEndorsement({ subscriptionId })
                  }}
                />
              )
            ) : null}
            {isPlatform ? (
              <Row
                subtitle="Historique du devis"
                leftIcon="list-ul-light"
                key="3"
                href={buildUrl({
                  to: '/bak/pending-subscriptions/$subscriptionId/history',
                  params: { subscriptionId: props.subscriptionId },
                })}
              />
            ) : (
              <></>
            )}
            <Row
              subtitle="Annuler tous les changements"
              leftIcon="arrow-rotate-right-light"
              disabled={!hasChanges}
              onClick={props.onReset}
              key="4"
            />
          </RowContainer>
        </Dropdown>

        {props.nonDimensionalDataAfter.hasEndorsementInProgress || !props.nonDimensionalDataAfter.contract ? (
          <Button
            variant="primary"
            size="small"
            disabled={
              !hasChanges ||
              !props.nextEnabledByAllContributions ||
              (!!props.nonDimensionalDataAfter.contract && !isPlatform)
            }
            onClick={
              props.nonDimensionalDataAfter.contract && !hasOnlyUserInfosChanges ? () => show() : props.onValidate
            }
          >
            Sauvegarder
          </Button>
        ) : null}
      </ButtonGroup>
      {visible ? <EndorsementConfirmDialog onSubmit={validateChanges} onClose={hide} /> : undefined}
    </>
  )
})

function getProblemMessage(problem: EndorsementProblem): string {
  switch (problem.type) {
    case 'temporary-api-failure':
      return "Oups, nous rencontrons un problème temporaire d'API. Vous pouvez retenter votre action ! "

    case 'cannot-generate-agreed-terms':
      return problem.reason
  }
}
